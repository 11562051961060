<template>
  <div
    class="customButton_container global_full_screen_height global_container_center customButton_background"
    :class="getClass()"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "customButton",
  props: {
    size: {
      type: String,
      default: "middle",
    },
  },
  methods: {
    getClass() {
      return `customButton_${this.size}`;
    },
  },
};
</script>
<style >
.customButton_container {
  font-size: 4vw;
  color: #fff;
  white-space: nowrap;
}
.customButton_large {
  background: url("../../assets/images/large.png");
}
.customButton_middle {
  background: url("../../assets/images/middle.png");
  /* min-width: 21.33vw; */
  height: 8.33vw;
  padding: 0 2vw;
}
.customButton_small {
  background: url("../../assets/images/small.png");
}

.customButton_submit {
  background: url("../../assets/images/submit.png");
  min-width: 21.33vw;
  height: 8.33vw;
  padding: 0 2vw;
}
.customButton_background {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>